import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const op1 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "A sok éves, sikeres múlttal rendelkező világítástechnikai nagyker szeretett volna az online kiskereskedelem felé nyitni.  Reagálni kellett az egyre égetőbb digitális igényekre. Reszponzív webáruház fejlesztésére és egységes design tervezésére volt szükség.",
  displayXo: true,
  reverse: false,
  title: "Minden ami fény",
  picture: "/images/refs/nl/ref_color.png"
}

const op2 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Különböző terméktípusokat kombináltunk, hogy egyedi\n" +
    "termékajánlatokat hozzunk létre. A felhasználók online viselkedésének nyomon követését üzleti elemzőink és UX szakértőink is segítették.\n" +
    "Ezáltal egy innovatív rendszert alakítottunk ki.",
  displayXo: true,
  reverse: true,
  title: "Újító webshop készítés",
  picture: "/images/refs/nl/netlampa_2.png"
}


export const opInfoCards = [
  op1,
  op2
]

