import { Reference } from "../reference-interface"
import { opInfoCards } from "../../service-info-card-interface/used_by/netlampa-cards"

export const Netlampa : Reference = {
  monitorPic: "/images/imac_netlampa.png",
  siteName: "Netlampa webshop",
  siteDescription: "Egyedi webshop fejlesztéssel kellő szabadságot adtunk számukra az egyedi igények és az online kereskedelem felé nyitás terén, amivel újfajta célközönséget sikerült elérniük.",
  siteUrl: "https://www.netlampa.hu/",
  cards: opInfoCards,
  referencePics: [
    "/images/refs/nl/netlampa_also_1.png",
    "/images/refs/nl/netlampa_zold.png",
    "/images/refs/nl/netlampa_also_2.png",
  ],
  pageFunctions: [
    "Egyedi design",
    "Önálló adminfelület",
    "Ügyfélszolgálat",
    "Egyedi vásárlási megoldások"
  ],
  reversePageFunctions: false,
}
